import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const InactivityTimer = () => {
  const navigate = useNavigate();

  const INACTIVITY_TIMEOUT = 30 * 60 * 1000; //30m
  // const INACTIVITY_TIMEOUT = 10 * 1000;

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    const resetTimer = () => {
      clearTimeout(timeout);
      timeout = setTimeout(handleLogout, INACTIVITY_TIMEOUT);
    };

    const handleLogout = () => {
      localStorage.clear();
      navigate("/");
      window.location.reload();
    };

    window.addEventListener("mousemove", resetTimer);
    window.addEventListener("keydown", resetTimer);
    window.addEventListener("click", resetTimer);

    timeout = setTimeout(handleLogout, INACTIVITY_TIMEOUT);

    return () => {
      window.removeEventListener("mousemove", resetTimer);
      window.removeEventListener("keydown", resetTimer);
      window.removeEventListener("click", resetTimer);
      clearTimeout(timeout);
    };
  }, [navigate]);

  return null;
};

export default InactivityTimer;
