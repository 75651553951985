import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useNavigate,
} from "react-router-dom";
import About from "./pages/about";
import Login from "./pages/Login";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import ForgotPin from "./components/ForgotPin";
import SignUpForm from "./components/SignUpForm";
import KnowledgeCenter from "./components/KnowledgeCenter";
import "./index.css";
import LoginIdForm from "./pages/LoginIdForm";
import EpcJourney from "./components/EPC/EpcJourney";
import ForgotPassword from "./components/EPC/ForgotPassword/ForgotPassword";
import EpcDashboard from "./components/EPC/EpcJourney/EpcDashboard";
import ResetPassword from "./components/EPC/ResetPassword/ResetPassword";
import EpcLogin from "./components/EPC/EpcLogin";
import CohostForm from "./components/EPC/CohostForm";
import EntityLogin from "./pages/EntityInformation";
import EntityInfo from "./components/EntityInformationStation/EntityInfo";
import { IsoftForm } from "./components/IsoftForm";
import { Document } from "./pages/Document";
import GemDashboard from "./components/EPC/GemJourney/EpcDashboard";
import { RootState, useAppDispatch } from "./store/store";
import { Redirect } from "./components/IsoftForm/redirect";
import Test from "./InceptionProject/Test";
import LoginProforma from "./pages/LoginProforma";
import ProformaForm from "./components/Proforma/ProformaForm";
import PurlDashboard from "./components/PurlDashboard";
import TestLoginForm from "./components/TestRoute/TestLogin";
import TestLoginIdForm from "./components/TestRoute/TestLoginId";
import TestLayout from "./components/TestRoute/TestLayout";
import { setUserFolderId } from "./store/reducers/usersReducer";
import { setUserLogin } from "./store/reducers/loginReducer";
import { setStage } from "./store/reducers/stageReducer";
import { toggleSubmission } from "./store/reducers/tileReducer";
import InactivityTimer from "./helpers/InactivityTimer";

interface URL {
  url: string;
}

function App() {
  const epcUser = useSelector((state: RootState) => state.epcUser.email);
  const purlUser = useSelector((state: RootState) => state.login._id);
  const proformaUser = useSelector(
    (state: RootState) => state.proformaUser.name
  );
  const dispatch = useAppDispatch();
  const isoftUser = localStorage.getItem("epcUserDetail");

  const [customizations, setCustomizations] = useState({
    fontSize: "16px",
    fontFamily: "Arial, sans-serif",
    fontWeight: "normal",
    backgroundColor: "white",
    textColor: "black",
  });

  useEffect(() => {
    const fetchCustomizations = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await fetch(
          "https://api.inceptionfinancial.app/api/admin/customization",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        const data = await response.json();
        setCustomizations(data.data);
        applyCustomizations(data.data);
      } catch (error) {
        console.error("Error fetching customizations:", error);
      }
    };
    fetchCustomizations();
  }, []);

  const applyCustomizations = (data: any) => {
    document.documentElement.style.setProperty("--font-size", data.fontSize);
    document.documentElement.style.setProperty(
      "--font-family",
      data.fontFamily
    );
    document.documentElement.style.setProperty(
      "--font-weight",
      data.fontWeight
    );
    document.documentElement.style.setProperty(
      "--background-color",
      data.backgroundColor
    );
    document.documentElement.style.setProperty("--text-color", data.textColor);
  };

  const RedirectComponent = ({ url }: URL) => {
    const navigate = useNavigate();
    useEffect(() => {
      window.open(url, "_blank");
      navigate("/");
    }, [url]);

    return <div>Redirecting...</div>;
  };

  useEffect(() => {
    const folderId = localStorage.getItem("folderId");
    folderId && dispatch(setUserFolderId(folderId));

    const user = localStorage.getItem("user");
    user && dispatch(setUserLogin(JSON.parse(user)));

    const newStateData = localStorage.getItem("newStateData");
    newStateData && dispatch(setStage(JSON.parse(newStateData)));

    const tile2 = localStorage.getItem("tile2");
    tile2 && dispatch(toggleSubmission({ component: "tile2" }));

    const tile5 = localStorage.getItem("tile5");
    tile5 && dispatch(toggleSubmission({ component: "tile5" }));
  }, []);

  return (
    <BrowserRouter>
      <InactivityTimer />
      <Routes>
        {/* test routes */}
        <Route path="/test-login/:id" element={<TestLayout />} />
        <Route path="/test-login" element={<TestLoginIdForm />} />
        {/* test route end */}
        <Route path="/" element={<LoginIdForm />} />
        <Route
          path="/proforma"
          element={proformaUser ? <ProformaForm /> : <LoginProforma />}
        />
        <Route path="/:uniqueId" element={<Login />} />
        <Route path="dashboard" element={<Login />} />
        <Route path="/forgot-pin" element={<ForgotPin />} />
        <Route path="/forgot-pin/:uniqueid" element={<ForgotPin />} />
        <Route path="/new-client" element={<SignUpForm />} />
        <Route path="/knowledge-center" element={<KnowledgeCenter />} />
        {/* <Route path="/entity-login" element={<EntityLogin />} /> */}
        <Route
          path="/entity-formation"
          element={
            // purlUser ? <EntityInfo /> : <Navigate to="/entity-login" replace />
            purlUser ? <EntityInfo /> : <EntityLogin />
          }
        />
        <Route
          path="/ticket"
          element={
            <RedirectComponent url="https://forms.zoho.com/inceptionfinancial956/form/ITRequest" />
          }
        />
        <Route path="/epc" element={<EpcJourney />} />
        <Route path="/epc/forgot-password" element={<ForgotPassword />} />
        <Route path="/epc/reset-password" element={<ResetPassword />} />
        <Route path="/temp-dashboard" element={<PurlDashboard />} />
        <Route
          path="/epc/co-host"
          element={epcUser ? <CohostForm /> : <Navigate to="/epc" replace />}
        />
        <Route
          path="/epc/dashboard"
          element={epcUser ? <EpcDashboard /> : <Navigate to="/epc" replace />}
        />
        <Route
          path="/epc/gem-dashboard"
          element={epcUser ? <GemDashboard /> : <Navigate to="/epc" replace />}
        />
        <Route
          path="/epc/isoftpull/:id"
          // element={
          //   isoftUser || epcUser ? (
          //     <IsoftForm />
          //   ) : (
          //     <Navigate to="/epc" replace />
          //   )
          // }
          element={<IsoftForm />}
        />
        <Route path="/IF_after20" element={<Document />} />
        <Route path="/IF_FMV_Calculation" element={<Document />} />
        <Route path="/IF_Performance_Report" element={<Document />} />
        <Route path="/3468InvestmentCreditTips" element={<Document />} />
        <Route path="/ExampleProductionReport" element={<Document />} />
        <Route path="/QualifyingActivitiesExample" element={<Document />} />

        {/* <Route path="/redirect/isoftpull" element={<Redirect />} /> */}
        {/* <Route path="/redirect-to-isoftpull" element={<Redirect />} /> */}
        {/* <Route path="/test" element={<Test />} /> */}
        <Route path="*" element={<>404 page not found</>} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
