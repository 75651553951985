import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

interface ProformaUser {
  name: string;
  designation?: string;
  inceptionDevFee?: string;
  developerDevFee?: string;
  itcPercent?: string;
  folderId?: string;
  lowScenerio?: string;
  lowMidScenerio?: string;
  highMidScenerio?: string;
  highScenerio?: string;
}

const initialState: ProformaUser = {
  name: "",
  designation: "",
  inceptionDevFee: "",
  developerDevFee: "",
  itcPercent: "",
  folderId: "",
  lowScenerio: "",
  lowMidScenerio: "",
  highMidScenerio: "",
  highScenerio: "",
};

const proformaUserSlice = createSlice({
  name: "proforma_user",
  initialState,
  reducers: {
    setProformaUser: (state, action: PayloadAction<ProformaUser>) => {
      state.name = action.payload.name;
      state.designation = action.payload?.designation;
      state.inceptionDevFee = action.payload?.inceptionDevFee;
      state.developerDevFee = action.payload?.developerDevFee;
      state.itcPercent = action.payload?.itcPercent;
      state.folderId = action.payload?.folderId;
      state.lowScenerio = action.payload?.lowScenerio;
      state.lowMidScenerio = action.payload?.lowMidScenerio;
      state.highMidScenerio = action.payload?.highMidScenerio;
      state.highScenerio = action.payload?.highScenerio;
    },
    resetProformaUser: (state) => {
      state.name = "";
    },
  },
});

export const { setProformaUser, resetProformaUser } = proformaUserSlice.actions;

export default proformaUserSlice.reducer;
