import clsx from "clsx";
import { useAppDispatch } from "../../store/store";
import { backStep, nextStep } from "../../store/reducers/stepReducer";
import styles from "./index.module.css";
import { useRef, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Input from "../Input";
import img from "../../assets/images/InceptionFinicallyLogo.png";
import { incomeSchema } from "../../yup";
import {
  useUpdateStagesMutation,
  useSubmitFormMutation,
  useGetStatesQuery,
  useRerunReportMutation,
  useGetPurlFlowQuery,
} from "../../services/api";
import Stepper from "../Stepper";
import Button from "../ButtonV2";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { setStage } from "../../store/reducers/stageReducer";

const SimpleNextIcon = ({ onClick, className }: any) => (
  <svg
    onClick={onClick}
    className={className}
    viewBox="0 0 24 24"
    width="24"
    height="24"
    xmlns="http://www.w3.org/2000/svg"
    style={{ cursor: "pointer" }}
  >
    <path d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6z" fill="#fff" />
  </svg>
);

const BackIcon = ({ onClick, className }: any) => (
  <svg
    onClick={onClick}
    className={className}
    viewBox="0 0 24 24"
    width="24"
    height="24"
    xmlns="http://www.w3.org/2000/svg"
    style={{ cursor: "pointer" }}
  >
    <path d="M15 16.59L10.41 12 15 7.41 13.59 6l-6 6 6 6z" fill="#fff" />
  </svg>
);

type Props = {
  setTable: React.Dispatch<React.SetStateAction<FormResponse | undefined>>;
};

export type Rerun = {
  _id: string;
  userId: {
    _id: string;
    zohoCustomerid: string;
  };
  inputId: {
    _id: string;
    equalityContribution: number;
    grossIncome: number;
    stateId: {
      _id: string;
      state: string;
    };
    estFederalTaxRate: number;
  };
  isEdited: boolean;
  isFavourite: boolean;
};

const Step3 = ({ setTable }: Props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    clearErrors,
    reset,
  } = useForm({
    resolver: yupResolver(incomeSchema),
  });

  const dispatch = useAppDispatch();
  const newRef = useRef<HTMLDivElement>(null);
  const stageData = useSelector((state: any) => state.stage);
  const zohoCustomerId = useSelector(
    (state: any) => state.login.zohoCustomerId
  );
  const inputProps = { register };

  const { data } = useGetStatesQuery();
  const [result, { error, isLoading }] = useSubmitFormMutation();
  const [result2, { error: error2, isLoading: isLoading2 }] =
    useRerunReportMutation();
  const [updateStage] = useUpdateStagesMutation();
  const { data: purlFlow } = useGetPurlFlowQuery();

  const [stateId, setStateId] = useState("");
  const [isError, setIsError] = useState("");
  const [state, setState] = useState<Option[]>([]);
  const [open, setOpen] = useState(false);
  const [field1, setField1] = useState(0);
  const [field2, setField2] = useState(0);
  const [isCalculated, setIsCalculated] = useState(false);

  const next = async () => {
    const newStateData = {
      ...stageData,
      presentationComplete: true,
    };
    dispatch(setStage(newStateData));
    dispatch(nextStep());
    const updatedStage = await updateStage({
      zohoId: zohoCustomerId,
      key: "benefitCalculatorComplete",
    });
  };

  const prev = () => {
    dispatch(backStep());
  };
  const onSubmit = async (values: any) => {
    setOpen(false);
    values.stateId = stateId;
    values.taxRate = values?.taxRate ? values.taxRate?.replace(/%/g, "") : 0;
    values.grossIncome =
      values.grossIncome == ""
        ? 0
        : parseInt(values?.grossIncome?.replace(/[$,]/g, ""));

    const data = (await result(values)) as Response<FormResponse>;
    if (data.data?.success) {
      const taxPayment = Math.round(
        (values.grossIncome * values.taxRate) / 100
      );
      setField1(taxPayment);
      setField2(data.data.data.totalBenefit);
      setIsCalculated(true);
      setTable(data.data.data);
      dispatch(setStage({ benefitCalculatorComplete: true }));
      reset();
    } else {
      setIsError(data.error.data?.message || data.error.message);
    }
    // }
  };

  const handleOutsideClick = (e: MouseEvent) => {
    if (newRef.current && !newRef.current.contains(e.target as Node)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    if (data) {
      const states = data?.data.map((value: any, index: any) => {
        return {
          label: value.state,
          value: index + 1,
          id: value._id,
        };
      });
      setState(states);
    }
  }, [data]);

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  });

  useEffect(() => {
    if (purlFlow && !purlFlow?.data[0].benefitCalculator) dispatch(nextStep());
  }, [purlFlow]);

  return (
    <div className={`${styles.content}`}>
      <div className={`${styles.imageContainer}`}>
        <img src={img} alt="" className={`${styles.image}`} />
      </div>
      <div className="">
        <div className="h-[80vh] lg:pt-[3%] pt-[20%] lg:w-[45vw] w-[90%] mx-auto relative ">
          <div className="flex flex-col justify-start items-start">
            <div
              className="video-heading"
              style={{
                fontSize: "1.6rem",
                color: "white",
                fontWeight: "600",
                fontFamily: "Signika",
              }}
            >
              3. Calculate Your Potential Benefits
            </div>

            <p
              style={{
                color: "#E2E7E5",
                fontFamily: "Raleway",
                fontWeight: "800",
              }}
              className="my-2 text-lg"
            >
              Now, let's look more closely at your tax scenario and calculate
              your potential benefits.
            </p>
          </div>

          <form className={styles.inputBox} onSubmit={handleSubmit(onSubmit)}>
            <div className={styles.inputRow}>
              <Input
                placeholder="ESTIMATED ANNUAL INCOME (AGI IF KNOWN)"
                error={
                  errors?.grossIncome?.message
                    ? errors.grossIncome.message.toString()
                    : undefined
                }
                name="grossIncome"
                {...inputProps}
                val={getValues("grossIncome")}
                isDollar={true}
                // setValue={setValue}
              />
            </div>
            <div className={`${styles.inputRow} gap-2`}>
              <Input
                placeholder="STATE OF RESIDENCE"
                // error={errors?.state?.message}
                error={
                  errors?.state?.message
                    ? errors.state.message.toString()
                    : undefined
                }
                name="state"
                type="select"
                items={state}
                setValue={setValue}
                setStateId={setStateId}
                setOpen={setOpen}
                open={open}
                clearErrors={clearErrors}
                val={getValues("state")}
                {...inputProps}
              />
              <Input
                placeholder="ESTIMATED FEDERAL TAX RATE"
                error={
                  errors?.taxRate?.message
                    ? errors.taxRate.message.toString()
                    : undefined
                }
                name="taxRate"
                {...inputProps}
                val={getValues("taxRate")}
                isPercent={true}
                getValues={getValues}
              />
            </div>
            {/* {(isError || error) && (
              <p className="text-red-500 text-[16px] text-left">
                {isError || "Internal Server Error"}
              </p>
            )} */}
            <div className="flex justify-end">
              <Button
                // className={styles.submitButton}
                isLoading={isLoading || isLoading2}
                type="button"
                title="ESTIMATE MY BENEFITS"
              />
            </div>
          </form>

          {isCalculated && (
            <div className="my-2">
              <div className="text-white my-2">
                We've calculated your benefits based on the inputs you provided
                above.
              </div>

              <div className="flex" style={{ backgroundColor: "#03261A" }}>
                <div className="flex-auto flex flex-col justify-center items-center border-solid border-white border-r-2 p-8 bg-white-black">
                  <div
                    className="text-white text-sm"
                    style={{ fontFamily: "Raleway", fontWeight: "800" }}
                  >
                    Estimated tax due this Year:
                  </div>
                  <div
                    className="text-white text-sm"
                    style={{
                      fontFamily: "Raleway",
                      fontWeight: "800",
                      fontSize: "20px",
                      color: "#77B991",
                      marginTop: "5px",
                    }}
                  >
                    {field1 === 0 ? "$X" : `$${field1.toLocaleString()}`}
                  </div>
                </div>
                <div className="flex-auto flex flex-col justify-center items-center border-solid py-2 px-3">
                  <div
                    className="text-white text-sm"
                    style={{ fontFamily: "Raleway", fontWeight: "800" }}
                  >
                    Estimated 6-year tax saving with our strategy:
                  </div>
                  <div
                    className="text-white text-sm"
                    style={{
                      fontFamily: "Raleway",
                      fontWeight: "800",
                      fontSize: "20px",
                      color: "#77B991",
                      marginTop: "5px",
                    }}
                  >
                    {field2 === 0 ? "$X" : ` $${field2.toLocaleString()}`}
                  </div>
                </div>
              </div>

              <div
                className="text-xs flex justify-center "
                style={{
                  fontFamily: "Raleway",
                  fontWeight: "800",
                  color: "#77B991",
                  marginTop: "5px",
                }}
              >
                You can access a saved version of this benefit calculation and
                run multiple pro formas in the next step.
              </div>
            </div>
          )}
          <div className="flex justify-between ">
            <div>
              <button className={`${styles.btn} flex w-[100px]`} onClick={prev}>
                <BackIcon className=" top-0 bottom-0 ml-[21%] left-0 m-auto" />
                <span>Back</span>
              </button>
            </div>
            {stageData.benefitCalculatorComplete ? (
              <div>
                <button
                  onClick={next}
                  className={`${styles.btn} flex  w-[100px]`}
                >
                  <span>Next</span>
                  <SimpleNextIcon className=" top-0 bottom-0 mr-[21%] right-0 m-auto" />
                </button>
              </div>
            ) : (
              <div>
                <button disabled className={`${styles.btn1} flex  w-[100px]`}>
                  <span>Next</span>
                  <SimpleNextIcon className=" top-0 bottom-0 mr-[21%] right-0 m-auto" />
                </button>
              </div>
            )}
          </div>
        </div>
        <div className=" h-[20vh] flex items-start w-full">
          <Stepper step={3} />
        </div>
      </div>
    </div>
  );
};

export default Step3;
