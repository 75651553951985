import { useEffect } from "react";
import { setStage } from "../../store/reducers/stageReducer";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { nextStep } from "../../store/reducers/stepReducer";
import { useUpdateStagesMutation } from "../../services/api";

const InceptionPresentation = () => {
  const dispatch = useAppDispatch();
  const [updateStage] = useUpdateStagesMutation();
  const loginData = useAppSelector((state) => state.login);
  const stageData = useAppSelector((state) => state.stage);
  const zohoCustomerId = loginData.zohoCustomerId;

  const next = async () => {
    const newStateData = {
      ...stageData,
      presentationComplete: true,
    };
    dispatch(setStage(newStateData));
    dispatch(nextStep());
    await updateStage({
      zohoId: zohoCustomerId,
      key: "presentationComplete",
    });
  };

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (event.data === "presentationComplete") {
        next();
      }
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, [dispatch, stageData, zohoCustomerId]);

  return (
    <iframe
      src="/InceptionPresentation/index.html"
      style={{ width: "100vw", height: "100vh", border: "none" }}
      title="Inception Presentation"
      id="inceptionIframe"
    ></iframe>
  );
};

export default InceptionPresentation;
