import BlackLayout from "../../components/BlackLayout";

const Login = () => {
  return (
    <>
      <BlackLayout />
    </>
  );
};

export default Login;
