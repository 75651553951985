import React from "react";
import styles from "./index.module.css";
import { resetProformaUser } from "../../store/reducers/proformaReducer";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import img from "../../assets/images/InceptionFinicallyLogo.png";
import CustomButton from "../Button/CustomButton";
interface LinkProps {
  href: DriveIdReturn;
  setDriveFolderId: React.Dispatch<React.SetStateAction<DriveIdReturn>>;
  resetForm: () => void;
}
const ProformaResponse: React.FC<LinkProps> = ({
  href,
  setDriveFolderId,
  resetForm,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleLogout = () => {
    localStorage.clear();
    dispatch(resetProformaUser());
    navigate("/proforma");
  };

  const resetValues = () => {
    resetForm();
    setDriveFolderId({});
  };
  return (
    <div className={styles.page}>
      <div className={styles.header}>
        <div>
          {/* img */}
          <img src={img} alt="" className={`${styles.image}`} />
        </div>
        <div>
          {/* button */}
          <button
            onClick={handleLogout}
            className={`relative h-12 bg-[#3B755E] cursor-pointer rounded ${styles.logoutButton}`}
          >
            LOGOUT
          </button>
        </div>
      </div>
      <div className={styles.content}>
        <h1 className={`font-bold text-center ${styles.formHeader}`}>
          Your proforma is ready.
        </h1>
        {!href.externalClientFolderID && (
          <a
            className={styles.link}
            target="_blank"
            href={`https://drive.google.com/drive/folders/${href.folderId}`}
          >
            Click here to go to Google Drive Folder
          </a>
        )}
        {href.externalClientFolderID && (
          <a
            className={styles.link}
            target="_blank"
            href={`https://drive.google.com/drive/folders/${href.externalClientFolderID}`}
          >
            Click here to go to Google Drive Folder
          </a>
        )}
      </div>

      <div className="flex justify-evenly py-6">
        <CustomButton
          onClick={() => setDriveFolderId({})}
          buttonLabel="Rerun Scenario"
          style={{ width: "12rem" }}
        />
        <CustomButton
          onClick={resetValues}
          buttonLabel="New Scenario"
          style={{ width: "12rem" }}
        />
      </div>
    </div>
  );
};

export default ProformaResponse;
