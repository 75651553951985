import { useState } from "react";
import ProgressBar from "../ProgressBar";
import Button from "../ButtonV2";
import { EquityContribution } from "../EquityContribution";
import { RootState, useAppSelector } from "../../store/store";
import Loader from "../Loader";
import {
  useEntityInfoMutation,
  useGetEquityContributionStatusQuery,
  useSubmitEquityContributionMutation,
} from "../../services/api";
import { useDispatch } from "react-redux";
import { setStage } from "../../store/reducers/stageReducer";
import { setSubmission } from "../../store/reducers/tileReducer";
import EquityModalNew from "./EquityModalNew";

interface Props {
  isLoading: boolean;
  equityContributionLoader: boolean;
  setEquityContributionLoader: React.Dispatch<React.SetStateAction<boolean>>;
  apaData?: IApaDoc;
  refetchApa: any;
}

const Tile4 = ({
  isLoading,
  equityContributionLoader,
  setEquityContributionLoader,
  apaData,
  refetchApa,
}: Props) => {
  const dispatch = useDispatch();
  const stageData = useAppSelector((state: RootState) => state.stage);
  const entityInformationCollected = stageData.entityInformationCollected;
  const [equityResult, { isLoading: equityResultLoading }] =
    useEntityInfoMutation();
  const [equityContributionApi, { isLoading: equityContributionLoading }] =
    useSubmitEquityContributionMutation();
  const [openNoForm, setOpenNoForm] = useState(false);
  const [equityFormLoader, setEquityFormLoader] = useState(false);
  const [error, setError] = useState("");
  const [showEquityChart, setShowEquityChart] = useState(
    stageData.entityInformationCollected ?? false
  );
  const [isSoftCommitComplete, setIsSoftCommitComplete] = useState(
    stageData.softCommitComplete
  );
  const [equityContribution, setEquityContribution] = useState("");
  const [equityContributionSubmit, setEquityContributionSubmit] = useState("");
  const [showSignTile, setShowSignTile] = useState(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { data: ecData, refetch: refetchEC } =
    useGetEquityContributionStatusQuery();

  const handleSubmit = (e: any) => {
    e.preventDefault();
  };

  const formatNumber = (number: string) => {
    const numericValue = parseInt(number.replace(/[^0-9]/g, ""));
    if (!isNaN(numericValue)) {
      return "$" + numericValue.toLocaleString();
    }
    return "";
  };

  const handleInputChange = (event: any) => {
    const value = event.target.value.replace(/[^0-9.]/g, "");
    setEquityContribution(value);
  };

  const submitEquityData = async () => {
    setEquityContributionLoader(true);
    try {
      if (parseInt(equityContribution) < 50000) {
        setError("APA Document Minimum is $50,000");
        return;
      }
      setError("");
      const body = {
        equityContribution: equityContribution,
      };
      const data = (await equityContributionApi(body)) as Response<any>;
      if (data.data?.success) {
        setIsSoftCommitComplete(true);
        setEquityContributionSubmit(equityContribution);
        dispatch(
          setStage({
            ...stageData,
            softCommitComplete: true,
          })
        );
        dispatch(setSubmission({ component: "tile6", isSubmitted: false }));
        dispatch(setSubmission({ component: "tile5", isSubmitted: true }));
        setShowSignTile(true);
        refetchApa();
      }
    } catch (error) {
      console.error("There was a problem with your fetch operation:", error);
    } finally {
      setEquityContributionLoader(false);
    }
  };

  return (
    <div className={`bg-[#FEFCFB] rounded-xl w-full pt-1 h-[390px] shadow-lg`}>
      <h2
        className="text-[25px] mb-[8px] p-1 text-black font-normal"
        style={{ height: "10%" }}
      >
        Equity Contribution
      </h2>
      <div className="flex flex-col items-center justify-center border-t-4 border-[#EFEFF4] h-[70%]">
        {showEquityChart ? (
          <>
            {isSoftCommitComplete ? (
              <button
                onClick={() => setIsSoftCommitComplete(false)}
                className={`bg-[#356C58] text-white px-2 rounded-sm text-[15px] justify-center items-center flex mt-3 h-[28px]`}
              >
                Re-enter equity equityContribution
              </button>
            ) : (!apaData?.CAF_Signed &&
                !apaData?.MNDA_Signed &&
                !apaData?.OMA_Signed &&
                !apaData?.request_id) ||
              (apaData?.CAF_Signed &&
                apaData?.MNDA_Signed &&
                apaData?.OMA_Signed) ? (
              <form onSubmit={handleSubmit}>
                <div className={`mb-3 flex mt-4 justify-center`}>
                  <input
                    className={`w-full p-[2px] pl-[5px] border border-gray-400`}
                    placeholder="Annual Equity Contribution"
                    type="text"
                    name="text"
                    autoComplete="off"
                    value={formatNumber(equityContribution)}
                    onChange={handleInputChange}
                  />
                  <button
                    className={`bg-[#3B755E] rounded-sm text-white ml-2 w-[7rem]`}
                    onClick={submitEquityData}
                    disabled={equityResultLoading}
                  >
                    {equityContributionLoader ? (
                      <Loader isLoading={true} size={12} />
                    ) : (
                      "SUBMIT"
                    )}
                  </button>
                </div>
                {error && (
                  <div className="text-red-500 text-sm text-center">
                    {error}
                  </div>
                )}
              </form>
            ) : (
              <p className="text-[15px] mx-3">
                Your entity is currently being formed. This section will update
                once you receive a confirmation email that your entity has been
                created successfully.
              </p>
            )}

            {/* <div className={styles.tile2desp}>
        In order to meet this goal, your payment of 1/8 is due June 1.
      </div> */}
            {equityResultLoading ? (
              <h1 className="text-white flex justify-center mt-12">
                <Button isLoading={isLoading} title="" variant="light4" />
              </h1>
            ) : (
              <div className="flex flex-col items-center justify-center">
                <div className="">
                  <ProgressBar value={25} />
                </div>
                <div className="mt-2">
                  <span className="text-3xl">$Na</span>/{" "}
                  <span>
                    {equityContributionSubmit
                      ? formatNumber(equityContributionSubmit)
                      : "$Na"}
                  </span>
                </div>
              </div>
            )}
          </>
        ) : ecData?.data.exist ? (
          <p className="text-xs mx-3">
            Your entity is currently being formed. This section will update once
            you receive a confirmation email that your entity has been created
            successfully.
          </p>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div className="text-[17px] font-semibold">
              Do you have an entity? If your answer is NO, an Entity will be set
              up for you.
            </div>
            <div className="flex mt-3 w-1/2 justify-around">
              <button
                className="bg-[#3B755E] text-[12px] rounded-sm text-white h-[32px] w-[48px]"
                onClick={() => setIsOpen(true)}
              >
                YES
              </button>
              {isOpen && <EquityModalNew onClose={() => setIsOpen(false)} />}
              <button
                className="bg-[#ff0000ad] rounded-sm text-[12px] text-white h-[32px] w-[48px]"
                onClick={() => {
                  setOpenNoForm(true);
                }}
              >
                NO
              </button>
              {openNoForm && (
                <EquityContribution
                  setOpenNoForm={setOpenNoForm}
                  setShowEquityChart={setShowEquityChart}
                  refetchEC={refetchEC}
                />
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Tile4;
