import { useState } from "react";
import { IoMdArrowDropright } from "react-icons/io";
import { IoMdArrowDropleft } from "react-icons/io";
import { RootState, useAppDispatch, useAppSelector } from "../../store/store";
import { setActive } from "../../store/reducers/tileReducer";
import useWindowResize from "../../hooks/useWindowResize";

const data = [
  "Learn more",
  "Schedule call with CPA",
  "Pro formas",
  "Equity Contribution",
  "My Documents",
  "Invite Others",
  "Sign Documents",
];

type TileMapping = {
  [key: string]: string;
};

const tileMapping: TileMapping = {
  "Learn more": "tile1",
  "Schedule call with CPA": "tile2",
  "Pro formas": "tile3",
  "Invite Others": "tile4",
  "Equity Contribution": "tile5",
  "Sign Documents": "tile6",
  "My Documents": "tile7",
};

interface IProps {
  apaData?: ApiResponse<IApaDoc>;
}

const SliderOption = ({ apaData }: IProps) => {
  const stageData = useAppSelector((state: RootState) => state.stage);
  const dispatch = useAppDispatch();
  const [startIndex, setStartIndex] = useState(0);
  const { width: screenWidth } = useWindowResize();

  const isVisible = (title: string) => {
    //change the condition in for loop if you add more cases
    switch (title) {
      case "Equity Contribution":
        return stageData.cpaCallComplete;
      case "Sign Documents":
        return apaData?.data?.request_id;
      case "My Documents":
        return stageData.softCommitComplete;
      default:
        return true;
    }
  };

  const getVisibleItems = () => {
    const visibleItems = [];
    for (let i = 0; visibleItems.length < Math.min(screenWidth / 400, 5); i++) {
      const index = (startIndex + i) % data.length;
      if (isVisible(data[index])) visibleItems.push(data[index]);
    }
    return visibleItems;
  };

  const handleNext = () => {
    setStartIndex((prevIndex) => (prevIndex + 1) % getVisibleItems().length);
  };

  const handlePrev = () => {
    setStartIndex((prevIndex) =>
      prevIndex === 0 ? data.length - 1 : prevIndex - 1
    );
  };

  const handleNav = (item: string) => {
    setStartIndex(data.indexOf(item));
    dispatch(setActive({ component: tileMapping[item] }));
  };

  return (
    <div className="h-[70px] bg-[#FEFCFB] flex justify-evenly">
      <div
        onClick={handlePrev}
        className="h-full text-2xl text-[#000] flex items-center justify-center "
      >
        <IoMdArrowDropleft />
      </div>
      {getVisibleItems().map((item: string, index: number) => {
        return (
          <div
            onClick={() => handleNav(item)}
            className={`hover:bg-[#CAD0DC] text-[13px] font-semibold text-nowrap flex justify-center items-center h-full w-full border-l-2 border-[#CAD0DC] ${
              !index && "border-b-2 border-b-green-500"
            }`}
          >
            {item.toUpperCase()}
          </div>
        );
      })}
      <div
        onClick={handleNext}
        className="h-full text-2xl text-[#000] border-l-2 border-[#CAD0DC] flex items-center justify-center"
      >
        <IoMdArrowDropright />
      </div>
    </div>
  );
};

export default SliderOption;
