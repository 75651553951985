import styles from "./index.module.css";
import Replay from "../../assets/images/Replay.png";
import ResouceLibary4 from "../../assets/images/ResourceLibraryImg4.png";
import img4 from "../../assets/images/LearnMore4.png";
import img5 from "../../assets/images/LearnMore5.png";
import img6 from "../../assets/images/solar-study-favicon.png";
import FolderImg from "../../assets/images/FolderLogo.png";
import MyDocumentTile from "../MyDocumentTile";
import pdfFile from "../../assets/pdf/Inception_Financial_Client_Presentation_-_08.22.20.pdf";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

interface Props {
  openOverlay: (url: string) => void;
  openAnimatedOverview: (url: string) => void;
  openOverlay1: (componentData: any) => void;
}

const Tile1 = ({ openOverlay, openAnimatedOverview, openOverlay1 }: Props) => {
  const solarStudyInvite = useSelector(
    (state: RootState) => state.login.solarStudyInvite
  );
  return (
    <div className={`${styles.tile} ${styles.customTile} `}>
      <h2 className={styles.heading}>Learn More</h2>
      <div className="grid gap-y-3 m-2 grid-cols-3">
        <div
          className={styles.tile1Box}
          onClick={() =>
            window.open(
              "https://learn.zohopublic.com/external/manual/inception-financial-knowledge-base?p=bf80f12473e7535675946df5b62034cc7fb015d0047609dbd60961acab25116f",
              "_blank"
            )
          }
        >
          <img className={styles.tile1Img} src={ResouceLibary4} alt="" />
          <h3 className={styles.tileheading}>KNOWLEDGE CENTER</h3>
        </div>
        <div
          className={styles.tile1Box}
          onClick={() =>
            openAnimatedOverview(
              "https://player.vimeo.com/video/949543563?h=1cc197bf6a&badge=0&autopause=0&amp;&player_id=0&app_id=58479"
            )
          }
        >
          <img className={styles.replay} src={Replay} alt="" />
          <h3 className={styles.tileheading}>ANIMATED OVERVIEW</h3>
        </div>
        <div
          className={styles.tile1Box}
          onClick={() =>
            openAnimatedOverview(
              "https://player.vimeo.com/video/1019597207?h=647157b9d6&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
            )
          }
        >
          <img className={styles.replay} src={Replay} alt="" />
          <h3 className={styles.tileheading}>CLIENT PRESENTATION VIDEO</h3>
        </div>
        <div
          className={styles.tile1Box}
          onClick={() =>
            openAnimatedOverview(
              "https://player.vimeo.com/video/955081780?h=7c048b1e7d&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
            )
          }
        >
          <img className={styles.replay} src={Replay} alt="" />
          <h3 className={styles.tileheading}>PRO FORMA REVIEW</h3>
        </div>
        <div
          className={styles.tile1Box}
          onClick={() =>
            openOverlay(
              "https://drive.google.com/file/d/16LFGBJBoD4jGzcbcR4vbrXV8jJFQjGKX/preview"
            )
          }
        >
          <img className={styles.learnMoreImg} src={img4} alt="" />
          <h3 className={styles.tileheading}>ONE PAGE OVERVIEW</h3>
        </div>
        <div className={styles.tile1Box} onClick={() => openOverlay(pdfFile)}>
          <img className={styles.learnMoreImg} src={img5} alt="" />
          <h3 className={styles.tileheading}>CLIENT PRESENTATION PPT</h3>
        </div>
        <div
          className={styles.tile1Box}
          onClick={() =>
            openOverlay1(
              <MyDocumentTile
                folder={{ id: "1rKmZ2sOsOCuJcRGvddP54yZGJsAXLetc" }}
                type="FromLearnMore"
                heading="Sample Asset Documents"
              />
            )
          }
        >
          <img className={styles.learnMoreImg} src={FolderImg} alt="" />
          <h3 className={styles.tileheading}>SAMPLE ASSET DOCUMENTS</h3>
        </div>
        <div
          className={styles.tile1Box}
          onClick={() =>
            openOverlay1(
              <MyDocumentTile
                folder={{ id: "1f6aIYHRSphrs6BteWYXBmzR5vPLfLATF" }}
                type="FromLearnMore"
                showSidebar={false}
                heading="Sample Agreements"
              />
            )
          }
        >
          <img className={styles.learnMoreImg} src={FolderImg} alt="" />
          <h3 className={styles.tileheading}>SAMPLE AGREEMENTS</h3>
        </div>
        <div
          className={styles.tile1Box}
          onClick={() =>
            openOverlay1(
              <MyDocumentTile
                folder={{ id: "1TJmrVANBJgeKeNDTnKx3PD_Qt74ZDrqU" }}
                type="FromLearnMore"
                showSidebar={false}
                heading="Sample Tax Documents"
              />
            )
          }
        >
          <img className={styles.learnMoreImg} src={FolderImg} alt="" />
          <h3 className={styles.tileheading}>SAMPLE TAX DOCUMENTS</h3>
        </div>
        {solarStudyInvite && (
          <div
            className="flex flex-col items-center justify-center cursor-pointer place-content-center h-[70%]"
            onClick={() => window.open("https://solar.study/", "_blank")}
          >
            <img className="h-[60px] mb-[5px]" src={img6} alt="" />
            <h3 className="text-center text-[10px]">SOLAR STUDY</h3>
          </div>
        )}

        {/* <div
              className={styles.tile1Box}
              onClick={() =>
                openOverlay(
                  "https://learn.zohopublic.com/external/manual/inception-financial-sample-tax-forms?p=bf80f12473e7535675946df5b62034ccbdad2a880b0a94901c686bd8bc9127c4"
                )
              }
            >
              <img className={styles.tile1Img} src={Replay} alt="" />
              <h3 className={styles.tileheading}>SAMPLE TAX FORMS</h3>
            </div> */}
      </div>
    </div>
  );
};

export default Tile1;
