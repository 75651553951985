import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

interface UserState {
  folderId: string;
  uniqueId: string;
}

const initialState: UserState = {
  folderId: "",
  uniqueId: "",
};

export const userSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<UserState>) => {
      state = action.payload;
      return state;
    },

    resetUser: (state) => {
      state = initialState;
      return state;
    },
    setUserFolderId: (state, action: PayloadAction<string>) => {
      state.folderId = action.payload;
      return state;
    },
    setUserUniqueId: (state, action: PayloadAction<string>) => {
      state.uniqueId = action.payload;
      return state;
    },
  },
});

export const { setUser, resetUser, setUserFolderId, setUserUniqueId } =
  userSlice.actions;

export default userSlice.reducer;
