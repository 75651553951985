import { mixed, number, boolean, object, string } from "yup";

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const loginSchema = object({
  pin: string()
    .trim()
    .required("Pin is required")
    .min(4, "Pin must be at least 4 characters long")
    .typeError("Pin must be a valid number"),
});
const emailSchema = object({
  email: string()
    .trim()
    .email("Please Enter the valid email")
    .required("Email is required")
    .typeError("Please enter the valid email"),
});

const epcLoginSchema = object({
  email: string()
    .trim()
    .email("Please Enter the valid email")
    .required("Email is required")
    .typeError("Please enter the valid email"),
  password: string()
    .trim()
    .required("Password is required")
    .min(4, "Password must be at least 4 characters long"),
});

const proformaLoginSchema = object({
  name: string()
    .trim()
    .required("Name is required")
    .typeError("Please enter the valid Name"),
  pin: string()
    .trim()
    .required("Password is required")
    .min(4, "Password must be at least 4 characters long")
    .max(6, "Password must be at most 6 characters long"),
});

const proformaFormSchema = object({
  clientName: string()
    .trim()
    .required("Client Name is required")
    .typeError("Please enter the valid Name"),
  equityContribution: string()
    .trim()
    .typeError("Please enter the valid Name")
    .test("required", "Equity Amount is required", function (value, context) {
      if (context.options.context?.isEquity && !value) {
        return this.createError({
          message: "Equity Contribution is required",
        });
      }
      if (context.options.context?.isEquity && value) {
        return true;
      }
      return true;
    }),
  ITCAmount: string()
    .trim()
    .test("required", "ITC Amount is required", (value, context) => {
      if (!context.options.context?.isITC && !value) return false;

      if (!context.options.context?.isITC && value) {
        return true;
      }
      return true;
    }),
  adjustedGrossIncome: string()
    .trim()
    .test("required", "Adjusted Gross Income is required", (value, context) => {
      if (context.options.context?.isGross && !value) return false;

      if (context.options.context?.isGross && value) {
        return true;
      }
      return true;
    }),
  partnerMarkup: string()
    .trim()
    .test(
      "required",
      "Partner Markup % should be less than 5%",
      (value, context) => {
        if (context.options.context?.isExternal && !value) return false;
        if (value && parseFloat(value.replace("/%/", "")) > 5) return false;
        return true;
      }
    ),
  year1DepreciationUsed: string().trim(),
  monthlyPlacedInService: string(),
  federalBonusDepreciationPercentage: string().trim(),
  stateSelector: mixed(),
  stateTaxRate: string().trim().required(),
  stateTaxRateYr7to20: string().trim().required(),
  statePTEPayment: string().trim().required(),
  stateBonusDepreciation: boolean()
    .required()
    .oneOf([true, false], "Please select a valid option"),
  stateBonusDepreciationAsFED: boolean()
    .required()
    .oneOf([true, false], "Please select a valid option"),
  stateRevenueSpread: boolean()
    .required()
    .oneOf([true, false], "Please select a valid option"),
  inceptionDevFee: string().trim().required(),
  ITCPercentage: string().trim().required(),
  developerDevFee: string().trim().required(),
  federalMarginalTaxRate: string().trim().required(),
  scenarioName: string().trim().required(),
});

const homeownerSchema = object({
  email: string()
    .trim()
    .email("Please Enter the valid email")
    .required("Email is required")
    .typeError("Please enter the valid email"),
  firstName: string().trim().required("First name is required"),
  lastName: string().trim().required("Last name is required"),
});

const coHostSchema = object().shape({
  email: string()
    .trim()
    .email("Please Enter the valid email")
    .required("Email is required")
    .typeError("Please enter the valid email"),
  firstName: string().trim().required("First name is required"),
  lastName: string().trim().required("Last name is required"),
  cosignerId: mixed<File>(),
  // .test(
  //   "required",
  //   "Please upload a file",
  //   (file: any) => {
  //     if (file?.[0] && file?.[0].size) return true;
  //     return false;
  //   }
  // ),
});

const siteSchema = object({
  siteAddress: string().trim().required("Site address is required"),
  blendedUtilityRate: string()
    .trim()
    .required("Blended utility rate is required"),
});
const referalSchema = object({
  firstName: string().trim().required("First name is required"),
  lastName: string().trim().required("Last name is required"),
  email: string()
    .trim()
    .email("Please Enter the valid email")
    .required("Email is required")
    .typeError("Please enter the valid email"),
});

const ppaProjectSchema = object({
  pvSize: string().trim().required("PV size is required"),
  batterySize: string().trim().required("Battery size is required"),
  yearOneProduction: string().trim().required("Year 1 production is required"),
});

const turnKeyPriceSchema = object({
  turnKeyPrice: string().trim().required("Turn key price is required"),
});

const reportSchema = object({
  softCommitment: string()
    .trim()
    .required("Soft Commitment is Required")
    .typeError("Soft Commitment is Required"),
});

const incomeSchema = object({
  grossIncome: string().trim().required("Gross Income is required"),
  state: string().trim().required("State is required"),
  taxRate: string()
    .trim()
    .required()
    .typeError("Tax Rate must be a valid number"),
});

const forgotPasswordSchema = object({
  email: string()
    .trim()
    .email("Please Enter the valid email")
    .required("Email is required")
    .typeError("Please enter the valid email"),
});

const resetPasswordSchema = object({
  newPassword: string()
    .trim()
    .required("New password is Required")
    .typeError("New password is Required")
    .min(4, "Password must be at least 4 characters long"),
  repeatPassword: string()
    .trim()
    .required("Repeat password is Required")
    .typeError("Repeat password is Required")
    .min(4, "Password must be at least 4 characters long"),
});

const editUserSchema = object().shape({
  customerId: mixed<File>().test(
    "required",
    "Please upload a file",
    (file, context) => {
      if (
        context.options.context?.isFileUploaded.customerId ||
        (file && file?.size)
      )
        return true;
      return false;
    }
  ),
  homeOwner: mixed<File>().test(
    "Atleast 1 file is required",
    "Please upload a file",
    (file, context) => {
      if (
        context.options.context?.isFileUploaded.homeOwner ||
        (file && file?.size)
      )
        return true;
      return false;
    }
  ),
  auth: mixed<File>().test(
    "Atleast 1 file is required",
    "Please upload a file",
    (file, context) => {
      if (context.options.context?.isFileUploaded.auth || (file && file?.size))
        return true;
      return false;
    }
  ),
  trust: mixed<File>().test(
    "Atleast 1 file is required",
    "Please upload a file",
    (file, context) => {
      if (context.options.context?.isFileUploaded.trust || (file && file?.size))
        return true;
      return false;
    }
  ),
  utility: mixed<File>().test(
    "Atleast 1 file is required",
    "Please upload a file",
    (file, context) => {
      if (
        context.options.context?.isFileUploaded.utility ||
        (file && file?.size)
      )
        return true;
      return false;
    }
  ),
  title: mixed<File>().test(
    "Atleast 1 file is required",
    "Please upload a file",
    (file, context) => {
      if (context.options.context?.isFileUploaded.title || (file && file?.size))
        return true;
      return false;
    }
  ),
  proposal: mixed<File>().test(
    "Atleast 1 file is required",
    "Please upload a file",
    (file, context) => {
      if (
        context.options.context?.isFileUploaded.proposal ||
        (file && file?.size)
      )
        return true;
      return false;
    }
  ),
  // solarId: string().trim().required("Open-solar id is required"),
  // solarIdVerify: string().required("Submit Open-solar id first"),
  solarId: string().trim(),
  solarIdVerify: string(),
});

const entityLoginSchema = object({
  email: string()
    .trim()
    .email("Please Enter the valid email")
    .required("Email is required"),
  pin: string()
    .trim()
    .required("Pin is required")
    .min(4, "Pin must be at least 4 characters long")
    .typeError("Pin must be a valid number"),
});

const entityCommentSchema = object({
  comment: string().required("Please add comment before submission"),
});

const newClientSchema = object({
  firstName: string().trim().required("First Name is required"),
  lastName: string().trim().required("Last Name is required"),
  state: mixed().required("State of residence is required"),
  mobilePhone: string()
    .trim()
    .matches(phoneRegExp, "Phone number is not valid"),
  email: string()
    .trim()
    .email("Please Enter the valid email")
    .required("Email is required"),
  annualIncome: mixed().required("Annual Income is required"),
  sourceOfIncome: mixed().required("Source of Income is required"),
  isAdvisor: string().trim().required("Select Yes/No to continue"),
});

const equityContributionSchema = object({
  email: string()
    .trim()
    .email("Please enter a valid email")
    .required("Email is required")
    .typeError("Please enter the valid email"),
  isCorporation: object({
    value: string().trim().required("Required"),
    label: string().trim().required("Required"),
  }).required("Required"),
  businessType: string().trim(),
  companyBuiltState: string().trim().required("Required"),
  qualityRegisterNewCompany: string().trim(),
  potentialCompanyName1: string().trim().required("Required"),
  potentialCompanyName2: string().trim(),
  potentialCompanyName3: string().trim(),
  companyMailingAddress: string().trim().required("Required"),
  socialSecurityNumber: string().trim().required("Required"),
  registerAgentRequired: object({
    value: string().trim().required("Required"),
    label: string().trim().required("Required"),
  }).required("Required"),
  driverLicense: mixed<FileList>()
    .required("A copy of your Driver's License is required.")
    .test("file", "File is required", (value) => {
      return value?.length !== 0;
    })
    .test("fileSize", "File should be less than 50MB", (value) => {
      if (!value || value.length === 0) return false;
      const MAX_SIZE = 50 * 1024 * 1024; // 50MB
      return (value[0] as File).size <= MAX_SIZE;
    }),
  registerAgentFullName: string().trim(),
  // .required('Required'), // No
  isContractAgentFee: object({
    //YES
    value: string().trim(),
    label: string().trim(),
  }),
  //   registerAgentFullName: string().trim().when(
  //   "registerAgentRequired",
  //   (registerAgentRequired, schema) => {
  //     return registerAgentRequired.value === "NO"
  //       ? schema.required("Required")
  //       : schema;
  //   }
  // ),
  // isContractAgentFee: object({
  //   value: string().trim(),
  //   label: string().trim(),
  // }).when("registerAgentRequired", {
  //   is: { value: "YES" },
  //   then: object().shape({
  //     value: string().trim().required("Required"),
  //     label: string().trim().required("Required"),
  //   }),
  // }),
  membersLegalName: string().trim(),
});

const isoftSchema = object({
  firstName: string().trim().required("First Name is required"),
  middleName: string().trim(),
  lastName: string().trim().required("Last Name is required"),
  address: string().trim().required("Address is required"),
  apt: string().trim(),
  city: string().trim().required("City is required"),
  state: object({
    value: string().trim().required("State is required"),
    label: string().trim().required("State is required"),
  }).required("Required"),
  zip: string().trim().required("ZIP is required"),
  socialSecurity: string().trim(),
  dob: string(),
  phone: string().trim(),
  email: string()
    .trim()
    .email("Please enter a valid email")
    .required("Email is required"),
});

const EntityYesSchema = object({
  entityName: string().required("Entity name is required"),
  entityType: mixed().required("Entity type is required"),
  stateOfIncorporation: mixed().required("State of incorporation is required"),
  businessTitle: string().required("Business title is required"),
  mailingStreet: string().required("Mailing street is required"),
  mailingCity: string().required("Mailing city is required"),
  mailingCountry: string().required("Mailing country is required"),
  mailingState: mixed().required("Mailing state is required"),
  mailingZip: string().required("Mailing zip is required"),
  // formationFile: mixed()
  //   .required("Formation Document Package is required")
  //   .test('fileType', 'File must be a PDF', (value: any) => {
  //     if (value instanceof File) {
  //       return value.type === 'application/pdf';
  //     }
  //     return false;
  //   })
  //   .test('fileSize', 'File size must be less than 10MB', (value: any) => {
  //     if (value instanceof File) {
  //       const MAX_SIZE = 10 * 1024 * 1024;
  //       return value.size <= MAX_SIZE;
  //     }
  //     return false;
  //   })
});

const noteSchema = object({
  note: string().trim().required("Note is required"),
});

const cpaInfoSchema = object({
  cpaName: string().trim().required("CPA Name is required"),
  cpaEmail: string()
    .trim()
    .email("Entered email must be a valid email")
    .required("CPA Email is required"),
  cpaPhone: string()
    .matches(/^\d+$/, "Phone number must be numeric")
    .min(8, "Phone number must be at least 8 digits")
    .max(15, "Phone number must be at most 15 digits")
    .required("CPA Phone number is required"),
  profilePic: string().optional(),
});

export {
  loginSchema,
  newClientSchema,
  coHostSchema,
  incomeSchema,
  reportSchema,
  emailSchema,
  siteSchema,
  epcLoginSchema,
  proformaLoginSchema,
  proformaFormSchema,
  homeownerSchema,
  forgotPasswordSchema,
  ppaProjectSchema,
  turnKeyPriceSchema,
  resetPasswordSchema,
  editUserSchema,
  equityContributionSchema,
  entityLoginSchema,
  isoftSchema,
  entityCommentSchema,
  EntityYesSchema,
  noteSchema,
  referalSchema,
  cpaInfoSchema,
};
