/* eslint-disable no-use-before-define */

import { useEffect, useState } from "react";

const TimerWidget = ({ userId }: { userId: string }) => {
  const [isWidgetLoaded, setIsWidgetLoaded] = useState(false);

  useEffect(() => {
    // Only load the widget script once the userId is available
    // eslint-disable-next-line
    if (userId && !isWidgetLoaded) {
      const script = document.createElement("script");
      script.src = "/timer-widget.js"; // Path to the external widget file
      script.onload = () => {
        // Initialize the widget once the script has loaded
        //@ts-ignore
        window.TimerWidget.init({
          elementId: "timerElement",
          userId: userId,
          name: "OUTSIDE-RESOURCES", // This could be dynamic as well
          url: "https://inceptionfinancial.app/", // This could be dynamic
          type: "OTHER-TIME", // This could be dynamic
          apiUrl: "https://solar.study/api/webhook-v2/activity/embedded", // Your backend API
        });
        setIsWidgetLoaded(true);
      };
      document.body.appendChild(script);
    }
  }, [userId, isWidgetLoaded]);

  return (
    <div>
      <div id="timerElement"></div>
    </div>
  );
};

export default TimerWidget;
