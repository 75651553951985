import styles from "./index.module.css";
import Loader from "../Loader";
import {
  useEntityInfoMutation,
  useGetStatesQuery,
  useSetEntityStatusMutation,
  useUploadLicenceMutation,
} from "../../services/api";
import Select from "react-select";
import CustomInput from "../CustomInput";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { EntityYesSchema } from "../../yup";
import CustomButton from "../Button/CustomButton";
import { useEffect, useState } from "react";
import { RootState, useAppSelector } from "../../store/store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

interface Props {
  setIsCompletedClicked: React.Dispatch<React.SetStateAction<boolean>>;
  zohoId: string;
}
interface State {
  _id: string;
  state: string;
}

interface StateOption {
  label: string;
  value: string;
}

const CompletedPopup = ({ setIsCompletedClicked, zohoId }: Props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({ resolver: yupResolver(EntityYesSchema) });

  const { data: states, isLoading } = useGetStatesQuery();
  const [equityResult, { isLoading: equityResultLoading }] =
    useEntityInfoMutation();
  const [setEntity, { isLoading: entityLoading }] =
    useSetEntityStatusMutation();
  const [uploadLicence, { isLoading: isUploading }] =
    useUploadLicenceMutation();

  const [stateOptions, setStateOptions] = useState<StateOption[]>();
  const [error, setError] = useState("");
  const [fileId, setFileId] = useState("");
  const [fileUploading, setFileUploading] = useState(false);

  const zohoCustomerId = useAppSelector(
    (state: RootState) => state.login.zohoCustomerId
  );

  const options = [
    { label: "LLC", value: "LLC" },
    { label: "Individual", value: "Individual" },
    { label: "S-Corp", value: "S-Corp" },
    { label: "C-Corp", value: "C-Corp" },
    { label: "Profit Corporation", value: "Profit Corporation" },
    { label: "Other", value: "Other" },
    { label: "Not Applicable", value: "Not Applicable" },
  ];

  const getStates = () => {
    const data = states?.data || [];
    const stateOption: StateOption[] = data.map((item: State) => {
      return {
        label: item.state,
        value: item._id,
      };
    });
    setStateOptions(stateOption);
  };

  const uploadFile = async (e: any) => {
    setFileUploading(true);
    if (!e.target.files || e.target.files.length === 0) return;
    const file = e.target.files[0];

    const formData = new FormData();
    formData.append("file", file);
    formData.append("contactId", zohoCustomerId);

    try {
      const response: any = await uploadLicence(formData);
      console.log(response);
      if (response.data.data.code == "SUCCESS") {
        const id = response.data.data.details.id;
        setFileId(id);
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      throw error;
    } finally {
      setFileUploading(false);
    }
  };

  useEffect(() => {
    getStates();
  }, [states]);

  const onClose = () => {
    setIsCompletedClicked(false);
  };

  const onSubmit = async (values: any) => {
    console.log(values);
    try {
      const formData = {
        entityName: values.entityName,
        entityType: values.entityType.value,
        stateOfIncorporation: values.stateOfIncorporation.value,
        businessTitle: values.businessTitle,
        mailingStreet: values.mailingStreet,
        mailingSuite: values.mailingSuite,
        mailingCity: values.mailingCity,
        mailingCountry: "United States",
        mailingState: values.mailingState.value,
        mailingZip: values.mailingZip,
        formationFile: fileId,
        isEmail: true,
        zohoCustomerId: zohoId,
      };

      const data: any = await equityResult(formData);
      if (!data.data.success) {
        setError(data.data.message || "Something went wrong");
      }
      if (data.data.success) {
        const statusData = await setEntity({
          zohoId: zohoId,
          status: "Completed",
        });
      }
      setIsCompletedClicked(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className={styles.overlay} onClick={onClose}>
      <div
        className={styles.overlayContent}
        onClick={(e) => e.stopPropagation()}
      >
        {error && (
          <div className="text-red-500 text-2xl text-center">{error}</div>
        )}
        {equityResultLoading && <Loader isLoading={equityResultLoading} />}
        {!equityResultLoading && !error && (
          <div>
            <span
              className={`absolute cursor-pointer right-5 top-3 text-4xl`}
              onClick={onClose}
            >
              &times;
            </span>
            <div className="text-2xl mb-4">Entity Information Form</div>
            <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
              <div className="flex items-center w-full py-[6px]">
                <div className="w-[40%] text-md ">
                  Entity Name
                  <FontAwesomeIcon
                    icon={faInfoCircle}
                    className={styles.infoIcon}
                  />
                  <div className={styles.tooltip}>
                    Please enter full legal name for your entity as it would do
                    business
                  </div>
                </div>
                <div className="w-full">
                  <CustomInput
                    name="entityName"
                    register={register}
                    error={errors.entityName}
                    type="text"
                    placeholder="Enter entity name"
                  />
                </div>
              </div>
              <div className="flex items-center w-full py-[6px]">
                <div className="w-[40%] text-md ">Entity Type*</div>
                <div className="w-full">
                  <Controller
                    name="entityType"
                    control={control}
                    render={({ field }: any) => (
                      <Select
                        {...field}
                        placeholder="Select entity type"
                        options={options}
                        onChange={(selectedOption) =>
                          field.onChange(selectedOption)
                        }
                      />
                    )}
                  />
                  {errors.entityType?.message && (
                    <p className="text-red-500 text-[14px] text-left font-semibold">
                      {errors.entityType?.message}
                    </p>
                  )}
                </div>
              </div>
              <div className="flex items-center w-full py-[6px]">
                <div className="w-[40%] text-md ">State of Incorporation*</div>
                <div className="w-full">
                  <Controller
                    name="stateOfIncorporation"
                    control={control}
                    render={({ field }: any) => (
                      <Select
                        {...field}
                        placeholder="Select state of incorporation"
                        isLoading={isLoading}
                        options={stateOptions}
                        onChange={(selectedOption) =>
                          field.onChange(selectedOption)
                        }
                      />
                    )}
                  />
                  {errors.stateOfIncorporation?.message && (
                    <p className="text-red-500 text-[14px] text-left font-semibold">
                      {errors.stateOfIncorporation?.message}
                    </p>
                  )}
                </div>
              </div>
              <div className="flex items-center w-full py-[6px]">
                <div className="w-[40%] text-md ">
                  Business Title
                  <FontAwesomeIcon
                    icon={faInfoCircle}
                    className={styles.infoIcon}
                  />
                  <div className={styles.tooltip}>
                    What is your title in the business? Common entries are
                    Owner, Managing Member and CEO
                  </div>
                </div>
                <div className="w-full">
                  <CustomInput
                    name="businessTitle"
                    register={register}
                    error={errors.businessTitle}
                    type="text"
                    placeholder="Enter business title"
                  />
                </div>
              </div>
              <div className="flex items-center w-full py-[6px]">
                <div className="w-[40%] text-md ">Mailing Street*</div>
                <div className="w-full">
                  <CustomInput
                    name="mailingStreet"
                    register={register}
                    error={errors.mailingStreet}
                    type="text"
                    placeholder="Enter mailing street"
                  />
                </div>
              </div>
              <div className="flex items-center w-full py-[6px]">
                <div className="w-[40%] text-md ">Mailing Suite</div>
                <div className="w-full">
                  <CustomInput
                    name="mailingSuite"
                    register={register}
                    type="text"
                    placeholder="Enter mailing suite"
                  />
                </div>
              </div>
              <div className="flex items-center w-full py-[6px]">
                <div className="w-[40%] text-md ">Mailing Country*</div>
                <div className="w-full pointer-events-none">
                  <CustomInput
                    name="mailingCountry"
                    register={register}
                    type="text"
                    defaultValue="United States"
                    placeholder="Enter mailing country"
                  />
                </div>
              </div>
              <div className="flex items-center w-full py-[6px]">
                <div className="w-[40%] text-md ">Mailing State*</div>
                <div className="w-full">
                  <Controller
                    name="mailingState"
                    control={control}
                    render={({ field }: any) => (
                      <Select
                        {...field}
                        placeholder="Select State"
                        isLoading={isLoading}
                        options={stateOptions}
                        onChange={(selectedOption) =>
                          field.onChange(selectedOption)
                        }
                      />
                    )}
                  />
                  {errors.mailingState?.message && (
                    <p className="text-red-500 text-[14px] text-left font-semibold">
                      {errors.mailingState?.message}
                    </p>
                  )}
                </div>
              </div>
              <div className="flex items-center w-full py-[6px]">
                <div className="w-[40%] text-md ">Mailing City*</div>
                <div className="w-full">
                  <CustomInput
                    name="mailingCity"
                    register={register}
                    error={errors.mailingCity}
                    type="text"
                    placeholder="Enter mailing city"
                  />
                </div>
              </div>
              <div className="flex items-center w-full py-[6px]">
                <div className="w-[40%] text-md ">Mailing Zip*</div>
                <div className="w-full">
                  <CustomInput
                    name="mailingZip"
                    register={register}
                    error={errors.mailingZip}
                    type="text"
                    placeholder="Enter mailing zip"
                  />
                </div>
              </div>

              <div className="flex items-center w-full py-[6px]">
                <div className="w-[40%] text-md ">
                  Formation Document Package
                </div>
                <div className="w-full">
                  <CustomInput
                    name="formationFile"
                    register={register}
                    // error={errors.formationFile}
                    type="file"
                    onChange={uploadFile}
                  />
                </div>
              </div>
              <div className="flex items-end ml-auto w-[120px] mt-3">
                <CustomButton
                  isLoading={fileUploading}
                  buttonLabel="SUBMIT"
                  style={{ height: "40px" }}
                />
              </div>
            </form>
          </div>
        )}
      </div>
    </div>
  );
};

export default CompletedPopup;
