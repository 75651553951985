import { Dispatch, SetStateAction } from "react";
import { MdDownload } from "react-icons/md";
import CustomButton from "../Button/CustomButton";
import styles from "./index.module.css";

interface Props {
  data: EntityInformation[];
  setPopup: Dispatch<SetStateAction<boolean>>;
  setPopupData: Dispatch<SetStateAction<EntityInformation | undefined>>;
  downloadLoader: boolean;
  downloadIndex: number;
  downloadAttachment: (
    e: any,
    fileId: string,
    zohoId: string,
    index: number
  ) => void;
}

const EntityInfoTable = ({
  data,
  setPopup,
  setPopupData,
  downloadLoader,
  downloadIndex,
  downloadAttachment,
}: Props) => {
  const headers = [
    "Entity Status",
    "Email",
    "Entity Type",
    "Business Activity",
    "Registered State",
    "Other States",
    "Desired Co. Name1",
    "Desired Co. Name2",
    "Desired Co. Name3",
    "Co. Mailing Address",
    "SSN/EIN",
    "Hire Registered Agent?",
    "Ra Payment Agree?",
    "Registered Agent Name",
    "Members Legal Names",
    "ID Download",
    // "Status",
    // "Entity Status",
  ];

  const statusPriority: Record<EntityInformation["entityStatus"], number> = {
    open: 1,
    rejected: 2,
    completed: 3,
  };
  const filterData = [...data];

  filterData.sort((a: EntityInformation, b: EntityInformation): number => {
    const statusComparison: number =
      statusPriority[a.entityStatus.toLowerCase()] -
      statusPriority[b.entityStatus.toLowerCase()];

    if (statusComparison !== 0) {
      return statusComparison;
    }

    if (a.entityStatus.toLowerCase() === "open") {
      return new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime();
    } else if (
      a.entityStatus.toLowerCase() === "rejected" ||
      a.entityStatus.toLowerCase() === "completed"
    ) {
      return new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime();
    }

    return 0;
  });

  return (
    <table className="overflow-x-auto table-auto min-w-full shadow-md ">
      <thead>
        <tr className="whitespace-nowrap border-b-2 border-gray-900 h-[2rem]">
          {headers.map((item: string, index: number) => (
            <th className="text-gray-900 text-sm px-2" key={index}>
              {item.toUpperCase()}
            </th>
          ))}
        </tr>
      </thead>
      <tbody className="">
        {filterData?.map((item: EntityInformation, rowIndex: number) => {
          return (
            <tr
              key={rowIndex}
              onClick={() => {
                setPopup(true);
                setPopupData(item);
              }}
              className={`text-gray-600 text-center text-sm h-[4rem] hover:bg-gray-100 cursor-pointer border-b-2 font-normal whitespace-nowrap px-4`}
            >
              <td className="px-2">{item.entityStatus}</td>
              <td className="px-2">{item.email}</td>
              <td className="px-2">
                {item.isCorporation
                  ? "Corporation"
                  : "Limited Liability Company"}
              </td>
              <td className="px-2">{item.businessType}</td>
              <td className="px-2">{item.companyBuiltState}</td>
              <td className="px-2">{item.qualityRegisterNewCompany}</td>
              <td className="px-2">{item.potentialCompanyName1 ?? "-"}</td>
              <td className="px-2">{item.potentialCompanyName2 ?? "-"}</td>
              <td className="px-2">{item.potentialCompanyName3 ?? "-"}</td>
              <td className="px-2">{item.companyMailingAddress}</td>
              <td className="px-2">
                {item.entityStatus == "Open"
                  ? item.socialSecurityNumber
                  : "REDACTED " +
                    item.updatedAt.substring(5, 7) +
                    "." +
                    item.updatedAt.substring(8, 10) +
                    "." +
                    item.updatedAt.substring(0, 4)}
              </td>
              <td className="px-2">
                {item.registerAgentRequired ? "YES" : "NO"}
              </td>
              <td className="px-2">{item.isContractAgentFee ? "YES" : "-"}</td>
              <td className="px-2">{item.registerAgentFullName ?? "-"}</td>
              <td className="px-2">{item.membersLegalName ?? "-"}</td>
              <td
                onClick={(e) =>
                  downloadAttachment(
                    e,
                    item.driverLicence,
                    item.zohoCustomerId,
                    rowIndex
                  )
                }
                className="flex justify-center items-center h-[4rem]"
              >
                {downloadLoader && rowIndex == downloadIndex ? (
                  <CustomButton
                    buttonLabel=""
                    isLoading={true}
                    style={{ backgroundColor: "white" }}
                  />
                ) : (
                  <MdDownload className="text-2xl" />
                )}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default EntityInfoTable;
