import { useEffect, useState } from "react";
import styles from "./index.module.css";
import { SubmitHandler, useForm } from "react-hook-form";
import { FaCheck } from "react-icons/fa6";
import { GoDash } from "react-icons/go";
import {
  useReferUserMutation,
  useGetReferredUserQuery,
} from "../../services/api";
import { RootState, useAppSelector } from "../../store/store";
import Button from "../ButtonV2";
import { showNotification } from "../../services/showNotification";
import { yupResolver } from "@hookform/resolvers/yup";
import { referalSchema } from "../../yup";

const InviteOthers = () => {
  const {
    register,
    formState: { errors },
    reset,
    handleSubmit,
  } = useForm<IReferData>({
    resolver: yupResolver(referalSchema),
  });

  const zohoCustomerId = useAppSelector(
    (state: RootState) => state.login.zohoCustomerId
  );
  const [referUser, { isLoading, isSuccess, isError }] = useReferUserMutation();

  const {
    data: referredData,
    isLoading: referalLoading,
    refetch,
  } = useGetReferredUserQuery({
    id: zohoCustomerId,
  });

  const [referrals, setReferrals] = useState<(string | boolean)[][]>([
    [
      "Referrals",
      "Login",
      "Video",
      "Presentation",
      "Calculate Benefits",
      "Initial Call",
      "Entity Setup",
      "Dashboard",
    ],
  ]);

  const addReferrals = (referralArray: IReferResponse[]) => {
    const newReferrals = referralArray.map(({ stages, contact_details }) => [
      contact_details.First_Name + " " + contact_details.Last_Name,
      stages.PURL_Login_Complete,
      stages.Video_Complete,
      stages.Presentation_Complete_PURL,
      stages.Benefits_Calculator_Complete,
      stages.CPA_Call_Complete,
      stages.Entity_Information_Collected,
      stages.Soft_Commit_Complete,
    ]);
    setReferrals((prevReferrals) => [...prevReferrals, ...newReferrals]);
  };

  const onSubmit: SubmitHandler<IReferData> = async (values) => {
    try {
      const data = {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        crmContactId: zohoCustomerId,
      };
      const res: any = await referUser(data);
      if (res?.error) {
        throw new Error("Failed to refer user");
      }

      const newReferrals = [
        data.firstName + " " + data.lastName,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
      ];
      setReferrals((prevReferrals) => [...prevReferrals, newReferrals]);
      reset();
      setTimeout(() => {
        refetch();
      }, 5000);
    } catch (error: any) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    if (isSuccess) {
      showNotification("success", "Referred user added");
      refetch();
    }
    isError && showNotification("error", "Something went wrong");
  }, [isSuccess, isError]);

  useEffect(() => {
    if (referredData?.data?.response?.length! > 0) {
      setReferrals([
        [
          "Referrals",
          "Login",
          "Video",
          "Presentation",
          "Calculate Benefits",
          "Inital Call",
          "Entity Setup",
          "Dashboard",
        ],
      ]);
      addReferrals(referredData?.data?.response!);
    }
  }, [referredData]);

  const inputStyle = "h-[25px] text-sm p-1 mr-2 border border-gray-300";

  return (
    <div className={`${styles.tile} ${styles.customTile}`}>
      <h2 className={styles.heading}>Invite Others</h2>
      {isLoading ? (
        <h1 className="text-white flex justify-center items-center h-[80%]">
          <Button
            isLoading={isLoading || referalLoading}
            title=""
            variant="light4"
          />
        </h1>
      ) : (
        <div className="w-full">
          <form
            onSubmit={handleSubmit(onSubmit)}
            className={`${styles.inputRow} flex flex-col justify-center  w-full`}
          >
            <div className="mb-1 w-full flex">
              <div className="flex flex-col w-full">
                <input
                  className={inputStyle}
                  placeholder="First Name"
                  type="text"
                  {...register("firstName")}
                  autoComplete="off"
                />
                {errors?.firstName && (
                  <p className="text-red-500 text-xs">
                    {errors.firstName.message}
                  </p>
                )}
              </div>
              <div className="flex flex-col w-full">
                <input
                  className={inputStyle}
                  placeholder="Last Name"
                  type="text"
                  {...register("lastName")}
                  autoComplete="off"
                />
                {errors?.lastName && (
                  <p className="text-red-500 text-xs">
                    {errors.lastName.message}
                  </p>
                )}
              </div>
            </div>
            <div className="flex items-center">
              <div className="flex flex-col w-1/2">
                <input
                  className={inputStyle}
                  placeholder="Email Address"
                  type="email"
                  {...register("email")}
                  autoComplete="off"
                />
                {errors?.email && (
                  <p className="text-red-500 text-xs">{errors.email.message}</p>
                )}
              </div>
              <button className="bg-[#3B755E] h-[25px] rounded-sm text-white text-[10px] px-[8px] ">
                SUBMIT
              </button>
            </div>
          </form>

          <div>
            <div className="flex justify-center text-sm text-teal-800 mt-2 mb-3">
              Track Your Referrals
            </div>
            {referrals.length == 1 ? (
              <div className="text-2xl flex items-center text-[#3B755E] justify-center">
                No Referrals yet
              </div>
            ) : (
              <div className="flex flex-col justify-center items-start overflow-auto">
                {referrals.map((items, ind) => (
                  <div key={ind} className="flex">
                    {items.map((item, index) => (
                      <div
                        key={index}
                        className={`p-1.5 bg-white text-sm flex-1 w-[140px] ${
                          index == 0 ? "border-r-2" : ""
                        }`}
                      >
                        {typeof item === "boolean" ? (
                          item ? (
                            <FaCheck className="text-green-400 text-lg" />
                          ) : (
                            <GoDash className="text-lg" />
                          )
                        ) : (
                          <span className="text-black">{item}</span>
                        )}
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default InviteOthers;
