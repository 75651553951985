import styles from "./index.module.css";
import styles2 from "../../../components/ForgotPin/index.module.css";
import Button from "../../../components/ButtonV2";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { forgotPasswordSchema } from "../../../yup";
import { useState } from "react";
import { useSendLoginDetailsMutation } from "../../../services/api";
import { clsx } from "clsx";
import img from "../../../assets/images/InceptionFinicallyLogo.png";

interface ILoginResult {
  data: {
    data: any;
    message: string;
    success: boolean;
    error_code: number;
  };
}

const TestLoginIdForm = () => {
  const {
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(forgotPasswordSchema),
  });

  const [sendLoginDetails, { isLoading: loading }] =
    useSendLoginDetailsMutation();

  const [isError, setIsError] = useState(false);
  const [successStatus, setSuccessStatus] = useState(false);

  const onSubmit = async (values: any) => {
    setSuccessStatus(false);
    setIsError(false);
    try {
      const result = await sendLoginDetails(values).unwrap();
      if (result.success) setSuccessStatus(true);
    } catch (err: any) {
      setIsError(true);
      console.log("error", err);
    }
  };

  return (
    <div className={`${styles.container} md:flex justify-center`}>
      <div className=" flex justify-start px-10 pb-20 absolute top-0 left-0">
        <a href="https://www.inception.financial/" target="_blank">
          <img src={img} alt="" className={`${styles.image}`} />
        </a>
      </div>
      <div className="w-full">
        <form
          className={clsx(
            `flex w-full mb-20 items-center justify-center relative`
          )}
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="flex flex-col justify-center ">
            <label className={styles.heading}>
              Let us send you your personalized credentials
            </label>
            <div className="flex ">
              <div className={clsx(styles.inputBox, "flex items-center pl-1")}>
                <input
                  className={clsx(styles2.input, "w-full font-raleway")}
                  placeholder="Enter your email"
                  name="email"
                  type="email"
                  onChange={(e) => setValue("email", e.target.value)}
                  autoComplete="off"
                />
              </div>

              <div className="flex justify-center md:block ">
                <Button isLoading={loading} title="SUBMIT" />
              </div>
            </div>
            {errors && (
              <span className="text-red-500 text-sm w-[100%] mt-1  font-bold flex items-start justify-start capitalize">
                {errors.email?.message}
              </span>
            )}
            {isError && (
              <span className="text-red-500 text-sm w-[100%] mt-1  font-bold flex items-start justify-start capitalize">
                User not found
              </span>
            )}
            {successStatus && (
              <span className="text-green-100 text-sm w-[100%] mt-1  font-bold flex items-start justify-start capitalize">
                Check your email for login instructions
              </span>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default TestLoginIdForm;
