import { useEffect, useState } from "react";
import styles from "./index.module.css";
import Select from "react-select";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { equityContributionSchema } from "../../yup";
import {
  useEquityContributionMutation,
  useUploadLicenceMutation,
} from "../../services/api";
import { RootState, useAppSelector } from "../../store/store";
import CustomButton from "../Button/CustomButton";

interface IFormData {
  email?: string;
  isCorporation: { value: string; label: string };
  businessType?: string;
  companyBuiltState: string;
  qualityRegisterNewCompany?: string;
  potentialCompanyName1: string;
  potentialCompanyName2?: string;
  potentialCompanyName3?: string;
  companyMailingAddress: string;
  socialSecurityNumber: string;
  registerAgentRequired: { value: string; label: string };
  driverLicense: FileList;
  isContractAgentFee?: { value?: string; label?: string }; // Yes
  membersLegalName?: string;
  registerAgentFullName?: string; // No
}

interface IProps {
  setOpenNoForm: React.Dispatch<React.SetStateAction<boolean>>;
  setShowEquityChart: React.Dispatch<React.SetStateAction<boolean>>;
  refetchEC: any;
}

const corporationOptions = [
  { value: "Corporation", label: "Corporation" },
  { value: "Limited Liability Company", label: "Limited Liability Company" },
];

const agentOptions = [
  { value: "Yes", label: "Yes" },
  { value: "No", label: "No" },
];

const feeOptions = [{ value: "Yes", label: "Yes" }];

export const EquityContribution = ({
  setOpenNoForm,
  setShowEquityChart,
  refetchEC,
}: IProps) => {
  const {
    register,
    handleSubmit,
    getValues,
    watch,
    reset,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(equityContributionSchema),
  });

  const [entityContribution, { isError, isLoading: equityLoading }] =
    useEquityContributionMutation();
  const [uploadLicence, { isLoading }] = useUploadLicenceMutation();
  const zohoCustomerId = useAppSelector(
    (state: RootState) => state.login.zohoCustomerId
  );

  const [page, setPage] = useState("home");
  const [registerData, setRegisterData] = useState("");
  const [licenceId, setLicenceId] = useState("");
  const [status, setStatus] = useState<boolean>();

  const onClose = () => {
    // if (page == "ok") setShowEquityChart(true);
    setOpenNoForm(false);
    refetchEC();
  };

  const uploadFile = async (file: File) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("contactId", zohoCustomerId);

    try {
      const response: any = await uploadLicence(formData);
      console.log(response);
      if (response.data.data.code == "SUCCESS") {
        const id = response.data.data.details.id;
        setLicenceId(id);
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      throw error;
    }
  };

  const formSubmit: SubmitHandler<IFormData> = async (value: any) => {
    try {
      const sendData = {
        email: value.email,
        isCorporation: value.isCorporation.value,
        businessType: value.businessType,
        companyBuiltState: value.companyBuiltState,
        qualityRegisterNewCompany: value.qualityRegisterNewCompany,
        potentialCompanyName1: value.potentialCompanyName1,
        potentialCompanyName2: value.potentialCompanyName2,
        potentialCompanyName3: value.potentialCompanyName3,
        companyMailingAddress: value.companyMailingAddress,
        socialSecurityNumber: value.socialSecurityNumber,
        registerAgentRequired: value.registerAgentRequired.value,
        driverLicence: licenceId,
        isContractAgentFee: value.isContractAgentFee.value, // Yes
        membersLegalName: value.membersLegalName,
        registerAgentFullName: value.registerAgentFullName, // No
      };

      await entityContribution(sendData);
      reset();
      setStatus(isError);
      setPage("ok");
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const fileObj = watch("driverLicense");
    const file = fileObj?.[0];
    if (file != null) {
      const data = uploadFile(file);
    }
  }, [watch("driverLicense")]);

  return (
    <>
      <div className={styles.overlay} onClick={() => onClose()}>
        <form
          className={styles.overlayContent}
          onSubmit={handleSubmit(formSubmit)}
          onClick={(e) => e.stopPropagation()}
        >
          <span className={styles.closeBtn} onClick={() => onClose()}>
            &times;
          </span>
          {page === "home" && (
            <div>
              <div className={styles.inputBox}>
                <label htmlFor="email" className={styles.labels}>
                  Email*
                </label>
                <input
                  className={styles.textInputs}
                  placeholder="Your email"
                  type="email"
                  {...register("email")}
                  id="email"
                />
                {errors.email && (
                  <p className="text-red-500 text-[14px] text-left">
                    {errors.email.message}
                  </p>
                )}
              </div>
              <div className={styles.inputBox}>
                <label className={styles.labels}>
                  Corporation or Limited Liability Company?*
                </label>
                <Controller
                  name="isCorporation"
                  control={control}
                  render={({ field }: any) => (
                    <Select
                      {...field}
                      className={styles.selectInputs}
                      options={corporationOptions}
                      onChange={(selectedOption) =>
                        field.onChange(selectedOption)
                      }
                    />
                  )}
                />
                {errors.isCorporation && (
                  <p className="text-red-500 text-[14px] text-left mt-2">
                    {errors.isCorporation.value?.message}
                  </p>
                )}
              </div>
              <div className={styles.inputBox}>
                <label htmlFor="businessType" className={styles.labels}>
                  What is the primary type of business (i.e. Construction
                  Company, Clothing Store, Holding Company, etc.)
                </label>
                <input
                  className={styles.textInputs}
                  placeholder="Your answer"
                  type="text"
                  id="businessType"
                  {...register("businessType")}
                />
              </div>
              <div className={styles.inputBox}>
                <label htmlFor="companyBuiltState" className={styles.labels}>
                  What State would you like the company formed?*
                </label>
                <input
                  className={styles.textInputs}
                  placeholder="Your answer"
                  type="text"
                  id="companyBuiltState"
                  {...register("companyBuiltState")}
                />
                {errors.companyBuiltState && (
                  <p className="text-red-500 text-[14px] text-left">
                    {errors.companyBuiltState.message}
                  </p>
                )}
              </div>
              <div className={styles.inputBox}>
                <label
                  htmlFor="qualityRegisterNewCompany"
                  className={styles.labels}
                >
                  Would you like us to "Qualify" or "Register" your new company
                  to do business in any other states? If so, where?
                </label>
                <input
                  className={styles.textInputs}
                  placeholder="Your answer"
                  type="text"
                  id="qualityRegisterNewCompany"
                  {...register("qualityRegisterNewCompany")}
                />
              </div>
              <div className={styles.inputBox}>
                <label
                  htmlFor="potentialCompanyName1"
                  className={styles.labels}
                >
                  Potential Company Name 1*
                </label>
                <input
                  className={styles.textInputs}
                  placeholder="Your answer"
                  type="text"
                  id="potentialCompanyName1"
                  {...register("potentialCompanyName1")}
                />
                {errors.potentialCompanyName1 && (
                  <p className="text-red-500 text-[14px] text-left">
                    {errors.potentialCompanyName1.message}
                  </p>
                )}
              </div>
              <div className={styles.inputBox}>
                <label
                  htmlFor="potentialCompanyName2"
                  className={styles.labels}
                >
                  Potential Company Name 2
                </label>
                <input
                  className={styles.textInputs}
                  placeholder="Your answer"
                  type="text"
                  id="potentialCompanyName2"
                  {...register("potentialCompanyName2")}
                />
              </div>
              <div className={styles.inputBox}>
                <label
                  htmlFor="potentialCompanyName3"
                  className={styles.labels}
                >
                  Potential Company Name 3
                </label>
                <input
                  className={styles.textInputs}
                  placeholder="Your answer"
                  type="text"
                  id="potentialCompanyName3"
                  {...register("potentialCompanyName3")}
                />
              </div>
              <div className={styles.inputBox}>
                <label
                  htmlFor="companyMailingAddress"
                  className={styles.labels}
                >
                  Mailing Address of New Company*
                </label>
                <input
                  className={styles.textInputs}
                  placeholder="Your answer"
                  type="text"
                  id="companyMailingAddress"
                  {...register("companyMailingAddress")}
                />
                {errors.companyMailingAddress && (
                  <p className="text-red-500 text-[14px] text-left">
                    {errors.companyMailingAddress.message}
                  </p>
                )}
              </div>
              <div className={styles.inputBox}>
                <label htmlFor="socialSecurityNumber" className={styles.labels}>
                  Please provide your Social Security Number (For the Company
                  Employer Identification Application - EIN).*
                </label>
                <input
                  className={styles.textInputs}
                  placeholder="Your answer"
                  type="text"
                  id="socialSecurityNumber"
                  {...register("socialSecurityNumber")}
                />
                {errors.socialSecurityNumber && (
                  <p className="text-red-500 text-[14px] text-left">
                    {errors.socialSecurityNumber.message}
                  </p>
                )}
              </div>
              <div className={styles.inputBox}>
                <label
                  htmlFor="registerAgentRequired"
                  className={styles.labels}
                >
                  Do you require a Registered Agent?*
                </label>
                <Controller
                  name="registerAgentRequired"
                  control={control}
                  render={({ field }: any) => (
                    <Select
                      {...field}
                      className={styles.selectInputs}
                      options={agentOptions}
                      onChange={(selectedOption: {
                        label: string;
                        value: string;
                      }) => {
                        field.onChange(selectedOption);
                        setRegisterData(selectedOption.value);
                      }}
                    />
                  )}
                />
                {errors.registerAgentRequired && (
                  <p className="text-red-500 text-[14px] text-left mt-2">
                    {errors.registerAgentRequired.value?.message}
                  </p>
                )}
              </div>
              {/* yes */}
              {registerData === "Yes" && (
                <div className={styles.inputBox}>
                  <label htmlFor="isContractAgentFee" className={styles.labels}>
                    Are you okay paying a $125/year fee for us to contract an
                    agent?*
                  </label>
                  <Controller
                    name="isContractAgentFee"
                    control={control}
                    render={({ field }: any) => (
                      <Select
                        {...field}
                        className={styles.selectInputs}
                        options={feeOptions}
                        onChange={(selectedOption: {
                          label: string;
                          value: string;
                        }) => {
                          field.onChange(selectedOption);
                        }}
                      />
                    )}
                  />
                  {errors.isContractAgentFee && (
                    <p className="text-red-500 text-[14px] text-left mt-2">
                      {errors.isContractAgentFee.value?.message}
                    </p>
                  )}
                </div>
              )}
              {/* no */}
              {registerData === "No" && (
                <div className={styles.inputBox}>
                  <label
                    htmlFor="registerAgentFullName"
                    className={styles.labels}
                  >
                    Who will act as your registered agent? (Full Legal Name) *
                  </label>
                  <input
                    className={styles.textInputs}
                    placeholder="Your answer"
                    type="text"
                    id="registerAgentFullName"
                    {...register("registerAgentFullName")}
                  />
                  {errors.registerAgentFullName && (
                    <p className="text-red-500 text-[14px] text-left">
                      {errors.registerAgentFullName.message}
                    </p>
                  )}
                </div>
              )}
              <div className={styles.inputBox}>
                <label htmlFor="driverLicense" className={styles.labels}>
                  We need to file a BOI/CTA Filing to Fincen, please provide a
                  copy of your Driver's License.*
                </label>
                <input
                  className={styles.textInputs}
                  placeholder="Your answer"
                  type="file"
                  id="driverLicense"
                  {...register("driverLicense")}
                />
                {errors.driverLicense && (
                  <p className="text-red-500 text-[14px] text-left mt-2">
                    {errors.driverLicense.message}
                  </p>
                )}
              </div>
              <div className={styles.inputBox}>
                <label htmlFor="membersLegalName" className={styles.labels}>
                  Legal name of all Members, Managers, Shareholders
                </label>
                <input
                  className={styles.textInputs}
                  placeholder="Your answer"
                  type="text"
                  id="membersLegalName"
                  {...register("membersLegalName")}
                />
              </div>
              <div className={styles.inputBox}>
                <span className="mt-4">
                  {/* <button
                    type="submit"
                    style={{ backgroundColor: "#3B755E" }}
                    className={`${
                      isLoading && "pointer-events-none"
                    } p-2 rounded-md text-white`}
                  >
                    Submit
                  </button> */}
                  <CustomButton
                    buttonLabel="SUBMIT"
                    disabled={isLoading}
                    isLoading={isLoading || equityLoading}
                  />
                </span>
              </div>
              {status && (
                <p className="text-green-800 text-[14px] bg-green-200 rounded-md text-center">
                  Form submitted successfully
                </p>
              )}
              {status == false && (
                <p className="text-red-800 text-[14px] bg-red-200 rounded-md text-center">
                  Form submission failed
                </p>
              )}
            </div>
          )}
          {page === "ok" && (
            <div>
              <div className={styles.inputBox}>
                <div className={styles.labels}>
                  Thank you. We will be in contact as we work through your
                  entity creation.
                </div>
              </div>
              <div className={styles.inputBox}>
                <span>
                  <button
                    type="button"
                    className={styles.btn}
                    onClick={onClose}
                  >
                    Next
                  </button>
                </span>
              </div>
            </div>
          )}
          {/*                     
                    
                    {page === 'no1' &&
                        <div>
                            <div className={styles.inputBox}>
                                <label htmlFor="registerAgentFullName" className={styles.labels}>Who will act as your registered agent? (Full Legal Name) *
                                </label>
                                <input
                                    className={styles.textInputs}
                                    placeholder='Your answer'
                                    type="text"
                                    id='registerAgentFullName'
                                    // {...register('registerAgentFullName')}
                                />
                            </div>
                            <div className={styles.inputBox}>
                                <span>
                                    <button onClick={() => setPage('home')}>Back</button>
                                    <button className={styles.btn}>Next</button>
                                </span>
                            </div>
                        </div>
                    }
                    
                    {page === 'final' &&
                        <div>
                            <div className={styles.inputBox}>
                                <label htmlFor="membersLegalName" className={styles.labels}>Legal name of all Members, Managers, Shareholders
                                </label>
                                <input
                                    className={styles.textInputs}
                                    placeholder='Your answer'
                                    type="text"
                                    id='membersLegalName'
                                    {...register('membersLegalName')}
                                />
                            </div>
                            <div className={styles.inputBox}>
                                <span>
                                    <button onClick={() => setPage('home')}>Back</button>
                                    <button className={styles.btn}>Next</button>
                                </span>
                            </div>
                        </div>
                    } 
*/}
        </form>
      </div>
    </>
  );
};
